export const defaultMetaTagDescription =
  'Faça uma cotação do seguro auto em segundos, tudo online e melhor seguro pro seu carro ou moto, cote agora mesmo';

export const defaultTitle = 'Luna | O seguro online para seu carro ou moto';

export const aggregateRating = {
  ratingValue: '5.0',
  bestRating: '5.0',
  ratingCount: '200',
};
