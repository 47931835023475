import { whatsAppMsgLink } from '@components/organisms/buttons/WhatsAppFixedButton';
import OutlineButton from 'components/atoms/buttons/OutlineButton';
import PrimaryButton from 'components/atoms/buttons/PrimaryButton';
import Spacing from 'components/atoms/Spacing';
import { Paragraph } from 'components/atoms/Typography';
import { trackEvent } from 'lib/services/analytics';
import { FC, useEffect } from 'react';
import ReactModal from 'react-modal';

const CallModal: FC<{
  hideModal: () => void;
  isOpen: boolean;
}> = ({ hideModal, isOpen }) => {
  useEffect(() => {
    trackEvent('open call modal');
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={hideModal}
      style={{
        content: {
          borderRadius: 5,
          border: 'none',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 400,
          width: '90vw',
          textAlign: 'center',
          padding: 32,
          height: 'fit-content',
        },
      }}
    >
      <Paragraph style={{ fontSize: 18 }}>
        <strong>Quer falar com o nosso time?</strong>
      </Paragraph>

      <Spacing top={2} bottom={4}>
        <Paragraph>
          Nós estamos prontos para tirar todas as suas dúvidas.
        </Paragraph>
      </Spacing>

      <Spacing vertical={4}>
        <a href="tel:(11) 5039-9198">
          <OutlineButton
            isBlock
            onClick={() => {
              hideModal();
              trackEvent('clicked on make a call');
            }}
          >
            Quero ligar
          </OutlineButton>
        </a>
      </Spacing>
      <Spacing vertical={4}>
        <a
          href={whatsAppMsgLink({
            lunaNumber: 'chatwoot',
            text: '',
          })}
          target="_blank"
          rel="noreferrer"
        >
          <PrimaryButton
            isBlock
            style={{ backgroundColor: '#25d366' }}
            onClick={() => {
              hideModal();
              trackEvent('clicked on make a call');
            }}
          >
            Conversar pelo WhatsApp
          </PrimaryButton>
        </a>
      </Spacing>
    </ReactModal>
  );
};

export default CallModal;
