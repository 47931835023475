import { FC } from 'react';
import { defaultTitle, defaultMetaTagDescription } from 'lib/constants/seo';
import env from 'lib/constants/env';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import LINKS from '@lib/constants/links';
import { useAmp } from 'next/amp';

const SEO: FC<{
  title?: string;
  description?: string;
  socialImgUrl?: string;
  customAmp?: boolean;
}> = ({
  title = defaultTitle,
  description = defaultMetaTagDescription,
  socialImgUrl = LINKS.ogImage,
  customAmp = false,
}) => {
  const router = useRouter();

  const isAmp = useAmp();

  const asPath = router.asPath.replace('/index', '/');

  const canURL = env.baseUrl + (isAmp ? asPath.replace(/\/amp$/, '') : asPath);

  return (
    <NextSeo
      title={title}
      description={description}
      canonical={isAmp && !customAmp ? undefined : canURL}
      openGraph={{
        title,
        description,
        url: canURL,
        images: [{ url: socialImgUrl }],
        type: 'website',
      }}
      additionalLinkTags={
        !isAmp && customAmp
          ? [
              {
                rel: 'amphtml',
                href: `${canURL}/amp`,
              },
            ]
          : undefined
      }
    />
  );
};

export default SEO;
