import { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { notNull } from 'lib/helpers/misc';
import BREAKPOINTS from 'lib/constants/breakpoints';

const generateStyles = ({
  list,
  propertyName,
  multiplier = 1,
  unit = 'px',
}: {
  list: (number | string)[];
  propertyName: string;
  unit?: '%' | 'px';
  multiplier?: number;
}) => {
  let string = '';

  if (notNull(list[0])) {
    string += `${propertyName}: ${
      typeof list[0] === 'string' ? list[0] : list[0] * multiplier
    }${unit};`;
  }

  if (notNull(list[1])) {
    string += `
      @media screen and (min-width: 1025px) {
    ${propertyName}: ${
      typeof list[1] === 'string' ? list[1] : list[1] * multiplier
    }${unit};
  }
  `;
  }

  if (notNull(list[2])) {
    string += `
      @media screen and (min-width: ${BREAKPOINTS.medium}) {
    ${propertyName}: ${
      typeof list[2] === 'string' ? list[2] : list[2] * multiplier
    }${unit};
  }
  `;
  }

  return string;
};

const generateStylesV2 = ({
  list,
  propertyName,
}: {
  list?: string[] | string;
  propertyName: string;
  unit?: '%' | 'px';
  multiplier?: number;
}) => {
  if (!list) return '';

  if (typeof list === 'string') {
    return `${propertyName}: ${list};`;
  }

  let string = '';

  if (notNull(list[0])) {
    string += `${propertyName}: ${list[0]};`;
  }

  if (notNull(list[1])) {
    string += `
      @media screen and (min-width: 1025px) {
    ${propertyName}: ${list[1]};
  }
  `;
  }

  if (notNull(list[2])) {
    string += `
      @media screen and (min-width: ${BREAKPOINTS.medium}) {
    ${propertyName}: ${list[2]};
  }
  `;
  }

  return string;
};

type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'initial'
  | 'inherit';

type AlignItems =
  | 'end'
  | 'baseline'
  | 'inherit'
  | 'initial'
  | 'start'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | '-moz-initial'
  | 'revert'
  | 'unset'
  | 'self-end'
  | 'self-start'
  | 'normal'
  | 'stretch';
interface Props {
  width?: (number | 'auto')[];
  mt?: number[];
  ml?: number[];
  className?: string;
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  justifyContent?: JustifyContent | JustifyContent[];
  style?: CSSProperties;
  alignItems?: AlignItems | AlignItems[];

  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
}

const Wrapper = styled.div<
  Props & {
    widthPercent: (number | 'auto')[];
    mt: number[];
    ml: number[];
  }
>`
  box-sizing: border-box;
  ${({ widthPercent }) =>
    generateStyles({
      list: widthPercent,
      unit: '%',
      propertyName: 'width',
      multiplier: 100,
    })}
  ${({ mt }) =>
    generateStyles({
      list: mt,
      propertyName: 'margin-top',
    })}

  ${({ ml }) =>
    generateStyles({
      list: ml,
      propertyName: 'margin-left',
    })}

    ${({ justifyContent }) =>
    generateStylesV2({
      list: justifyContent,
      propertyName: 'justify-content',
    })}

     ${({ alignItems }) =>
    generateStylesV2({
      list: alignItems,
      propertyName: 'align-items',
    })}

  display: flex;
  flex-wrap: ${(props) => props.flexWrap};
  flex-direction: ${(props) => props.flexDirection};
`;

const GridBox: FC<Props> = ({
  width = [1],
  children,
  mt = [],
  ml = [],
  flexDirection,
  justifyContent = 'space-between',
  alignItems,
  flexWrap = 'wrap',
  ...rest
}) => (
  <Wrapper
    widthPercent={width}
    style={{ alignItems: '' }}
    mt={mt}
    flexDirection={flexDirection}
    justifyContent={justifyContent}
    alignItems={alignItems}
    ml={ml}
    flexWrap={flexWrap}
    {...rest}
  >
    {children}
  </Wrapper>
);

export default GridBox;
