import { FC } from 'react';
import COLORS from 'lib/constants/colors';
import { partnerConfig } from '@lib/helpers/partner';
import { inIframe } from '@lib/helpers/misc';

const Logo: FC<{ className?: string; color?: string }> = ({
  className,
  color = COLORS.black,
}) => {
  if (partnerConfig?.images?.logo && inIframe()) return null;

  if (partnerConfig?.images?.logo)
    return (
      <img
        src={partnerConfig?.images.logo.url}
        alt="Logo"
        className={className}
        style={{ height: partnerConfig.images.logo.height, maxWidth: '100%' }}
      />
    );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 521 205"
      className={className}
    >
      <path
        fill={color}
        d="M0 0h43v201h-3c-22.091 0-40-17.909-40-40V0zM68.993 148.72V62.67h42.452v77.894c0 8.7 2.404 15.86 7.212 21.478 4.807 5.619 11.202 8.429 19.185 8.429 7.438 0 13.606-2.674 18.504-8.021 4.99-5.437 7.484-12.416 7.484-20.935V62.669h42.316v139.204H163.83v-8.972c-11.339 7.975-24.537 12.008-39.595 12.099-16.328 0-29.617-5.211-39.867-15.633-10.25-10.422-15.375-23.971-15.375-40.647zM231.726 201.873V62.669h42.452v8.972c11.339-7.975 24.674-12.008 40.004-12.099 16.509 0 29.934 5.212 40.275 15.634 10.341 10.422 15.511 23.97 15.511 40.646v86.051h-42.316v-77.894c0-8.7-2.495-15.86-7.484-21.479-4.898-5.619-11.338-8.428-19.321-8.428-7.801 0-14.196 2.718-19.185 8.156-4.989 5.347-7.484 12.371-7.484 21.071v78.574h-42.452zM389.97 163.402c0-14.501 6.44-26.282 19.321-35.345 12.971-9.063 29.662-13.594 50.072-13.594 7.801 0 14.241.453 19.321 1.359v-5.573c0-4.441-2.404-8.021-7.211-10.74-4.717-2.81-10.886-4.214-18.505-4.214-14.151 0-26.851 3.897-38.099 11.691l-19.865-24.605c6.531-6.888 15.466-12.416 26.805-16.585 11.338-4.17 23.312-6.254 35.921-6.254 18.596 0 33.789 4.532 45.582 13.595C515.104 82.109 521 93.754 521 108.074v93.799h-42.316v-8.292C467.345 201.194 453.966 205 438.545 205c-14.332 0-26.034-3.897-35.105-11.691-8.98-7.885-13.47-17.854-13.47-29.907zm73.066-21.343c-8.345 0-15.193 1.586-20.545 4.758-5.261 3.081-7.892 7.069-7.892 11.963 0 3.716 1.769 6.752 5.307 9.108 3.537 2.266 7.982 3.399 13.334 3.399 7.619 0 13.742-2.402 18.369-7.205 4.717-4.894 7.075-11.51 7.075-19.848-5.624-1.45-10.84-2.175-15.648-2.175z"
      />
    </svg>
  );
};

export default Logo;
