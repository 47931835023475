import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import GhostButton from './GhostButton';
import CloseIcon from '../icons/CloseIcon';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  color?: string;
  style?: CSSProperties
}

const Wrapper = styled(GhostButton)`
  padding: 16px;
  width: 56px;
  height: 56px;
  border-radius: 100%;

`;

const CloseButton: FC<Props> = ({ onClick, color,style }) => (
  <Wrapper onClick={onClick} style={style}>
    <CloseIcon color={color} />
  </Wrapper>
);

export default CloseButton;
