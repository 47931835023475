import COLORS from 'lib/constants/colors';
import { FC } from 'react';
import styled from 'styled-components';
import PrimaryButton, { PrimaryButtonProps } from './PrimaryButton';

const StyledButton = styled(PrimaryButton)`
  &&& {
    background-color: transparent;
    color: ${(props) => (props.pink ? COLORS.pink : COLORS.black)};
    border: 1px solid ${(props) => (props.pink ? COLORS.pink : COLORS.black)};
  }
`;

const OutlineButton: FC<PrimaryButtonProps> = (props: PrimaryButtonProps) => (
  <StyledButton {...props} />
);

export default OutlineButton;
