import GhostButton from '@components/atoms/buttons/GhostButton';
import { Heading3 } from '@components/atoms/Typography';
import CallModal from '@components/molecules/modals/CallModal';
import ShareModal from '@components/molecules/modals/ShareModal';
import ROUTES from '@lib/constants/routes';
import { CarrierComparative } from '@lib/helpers/airtableBases';
import { useRouter } from 'next/router';
import ReactModal from 'react-modal';
import { useModal } from './modal/useModal';

export const useShareModal = ({
  shareInfo,
}: {
  shareInfo: {
    text: string;
    url: string;
  };
}) => {
  const modalFunctions = useModal(
    ({ in: open }) => (
      <ShareModal
        shareInfo={shareInfo}
        hideModal={modalFunctions[1]}
        isOpen={open}
      />
    ),
    [],
  );

  return modalFunctions;
};

export const useCallModal = () => {
  const modalFunctions = useModal(({ in: open }) => (
    <CallModal
      hideModal={() => {
        modalFunctions[1]();
      }}
      isOpen={open}
    />
  ));

  return modalFunctions;
};

export function useCompareCarrierModal({
  carriers,
  carriersToCompare,
}: {
  carriers: CarrierComparative[];
  carriersToCompare: CarrierComparative[];
}) {
  const router = useRouter();

  const [showModal, hideModal] = useModal(
    ({ in: open }) => (
      <ReactModal
        isOpen={open}
        onRequestClose={hideModal}
        style={{
          content: {
            borderRadius: 5,
            border: 'none',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            // height: isSmall ? '95%' : '70%',
            maxWidth: 600,
            width: '100vw',
            padding: 32,
            height: 'fit-content',
            bottom: 'auto',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          },
        }}
      >
        <Heading3 center>Escolha as seguradoras</Heading3>
        <div
          style={{
            display: 'grid',
            gridGap: 8,
            gridTemplateColumns: '1fr 1fr',
            marginTop: 32,
          }}
        >
          {carriers
            .filter((c) => !carriersToCompare.some((c2) => c2.slug === c.slug))
            .map((c) => (
              <GhostButton
                key={c.slug}
                onClick={() => {
                  hideModal();
                  return router.push(
                    ROUTES.carriers.compare([...carriersToCompare, c]),
                    undefined,
                    { shallow: !!carriersToCompare.length },
                  );
                }}
              >
                {c.nome}
              </GhostButton>
            ))}
        </div>
      </ReactModal>
    ),
    [carriersToCompare],
  );

  return [showModal, hideModal];
}
