import OutlineButton from '@components/atoms/buttons/OutlineButton';
import GridBox from '@components/atoms/GridBox';
import SocialIcon, { SocialTypes } from '@components/atoms/icons/SocialIcon';
import { Heading3 } from '@components/atoms/Typography';
import { copyTextToClipboard } from '@lib/helpers/misc';
import { facebookShareLink, twitterShare, whatsAppShareLink } from '@lib/helpers/share';
import { trackEvent } from 'lib/services/analytics';
import React, { FC, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useMediaQuery } from 'react-responsive';

const trackShare = ({ url, to }: { url: string; to: SocialTypes }) => {
  trackEvent('clicked on share', {
    url,
    to,
  });
};

const ShareModal: FC<{
  hideModal: () => void;
  isOpen: boolean;
  shareInfo: {
    text: string;
    url: string;
  };
}> = ({ hideModal, isOpen, shareInfo }) => {
  const isSmall = useMediaQuery({ query: '(max-width: 840px)' });
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  useEffect(() => {
    trackEvent('open share modal');
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={hideModal}
      style={{
        content: {
          borderRadius: 5,
          border: 'none',
          left: isSmall ? 0 : '50%',
          top: isSmall ? 'auto' : '50%',
          transform: isSmall ? undefined : 'translate(-50%, -50%)',
          // height: isSmall ? '95%' : '70%',
          maxWidth: isSmall ? 'none' : 600,
          width: '100vw',
          padding: 32,
          height: 'fit-content',
          bottom: isSmall ? 0 : 'auto',
          borderBottomLeftRadius: isSmall ? 0 : 5,
          borderBottomRightRadius: isSmall ? 0 : 5,
        },
      }}
    >
      <Heading3 center>Compartilhe com os amigos</Heading3>
      <GridBox mt={[16]} flexDirection="column">
        {[
          {
            href: whatsAppShareLink(`${shareInfo.text}\n${shareInfo.url}`),
            type: SocialTypes.whatsapp,
            text: 'WhatsApp',
            color: '#25d366',
          },
          {
            href: facebookShareLink(shareInfo.url),
            type: SocialTypes.facebook,
            text: 'Facebook',
            color: '#1877F2',
          },
          {
            href: twitterShare(shareInfo.text, shareInfo.url),
            type: SocialTypes.twitter,
            text: 'Twitter',
            color: '#1DA1F2',
          },
        ].map(({
          href, type, text, color,
        }) => (
          <a
            key={type}
            href={href}
            rel="noreferrer"
            target="_blank"
            onClick={() => trackShare({ url: shareInfo.url, to: type })}
          >
            <GridBox justifyContent="flex-start" alignItems="center" mt={[24]}>
              <SocialIcon type={type} size={32} color={color} />
              <span style={{ marginLeft: 12 }}>{text}</span>
            </GridBox>
          </a>
        ))}

        <OutlineButton
          style={{ marginTop: 32 }}
          onClick={() => {
            copyTextToClipboard(shareInfo.url);
            setCopied(true);
          }}
        >
          {copied ? '✅' : 'Copiar Link'}
        </OutlineButton>
      </GridBox>
    </ReactModal>
  );
};

export default ShareModal;
