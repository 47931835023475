import { FC } from 'react';
import COLORS from 'lib/constants/colors';

interface SocialProps {
  size: number;
  color: string;
}

const Instagram: FC<SocialProps> = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 36"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M18 0C8.059 0 0 8.059 0 18s8.059 18 18 18 18-8.059 18-18S27.941 0 18 0zm-3.957 8.458C15.067 8.411 15.393 8.4 18 8.4h-.003c2.608 0 2.934.011 3.958.058 1.022.047 1.72.209 2.332.446a4.692 4.692 0 011.7 1.107c.534.534.862 1.07 1.108 1.7.236.611.398 1.309.446 2.33.046 1.025.058 1.352.058 3.96 0 2.606-.012 2.933-.058 3.957-.048 1.021-.21 1.72-.446 2.33a4.713 4.713 0 01-1.108 1.7 4.697 4.697 0 01-1.7 1.108c-.61.238-1.309.4-2.33.446-1.025.047-1.35.058-3.958.058-2.607 0-2.934-.011-3.958-.058-1.022-.047-1.72-.208-2.33-.446a4.7 4.7 0 01-1.7-1.108 4.704 4.704 0 01-1.108-1.7c-.237-.61-.399-1.308-.446-2.33C8.412 20.933 8.4 20.607 8.4 18c0-2.607.012-2.934.058-3.959.046-1.021.208-1.719.446-2.33a4.713 4.713 0 011.108-1.7 4.7 4.7 0 011.7-1.107c.61-.237 1.308-.4 2.33-.446z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M17.14 10.13H18c2.564 0 2.868.01 3.88.055.936.043 1.444.2 1.783.33a2.97 2.97 0 011.103.719c.336.336.544.656.718 1.104.132.338.288.846.33 1.782.047 1.012.057 1.316.057 3.878 0 2.562-.01 2.866-.056 3.878-.043.936-.2 1.444-.33 1.782a2.968 2.968 0 01-.72 1.103 2.97 2.97 0 01-1.102.718c-.338.132-.847.288-1.783.33-1.012.047-1.316.057-3.88.057-2.563 0-2.866-.01-3.878-.056-.936-.044-1.444-.2-1.783-.331a2.973 2.973 0 01-1.104-.718 2.975 2.975 0 01-.718-1.104c-.132-.338-.288-.846-.331-1.782-.046-1.012-.055-1.316-.055-3.88 0-2.563.009-2.866.055-3.878.043-.936.2-1.444.33-1.782.175-.448.383-.768.719-1.104a2.98 2.98 0 011.104-.718c.338-.132.847-.288 1.783-.331.885-.04 1.229-.052 3.018-.054v.002zm5.985 1.594a1.152 1.152 0 100 2.304 1.152 1.152 0 000-2.304zM13.071 18a4.93 4.93 0 119.86 0 4.93 4.93 0 01-9.86 0z"
      clipRule="evenodd"
    />
    <path fill={color} d="M18 14.8a3.2 3.2 0 110 6.4 3.2 3.2 0 010-6.4z" />
  </svg>
);

const Facebook: FC<SocialProps> = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 36"
  >
    <path
      fill={color}
      d="M36 18c0-9.942-8.058-18-18-18S0 8.058 0 18c0 8.986 6.581 16.432 15.188 17.782V23.203h-4.57V18h4.57v-3.966c0-4.51 2.685-7.003 6.799-7.003 1.969 0 4.029.352 4.029.352v4.43h-2.271c-2.236 0-2.933 1.388-2.933 2.812V18h4.993l-.798 5.203h-4.195v12.579C29.42 34.432 36 26.986 36 18z"
    />
  </svg>
);

const Twitter: FC<SocialProps> = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 36"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M18 0C8.059 0 0 8.059 0 18s8.059 18 18 18 18-8.059 18-18S27.941 0 18 0zm-.501 15.254l-.038-.623c-.113-1.615.881-3.09 2.455-3.661.58-.204 1.561-.229 2.203-.051.252.076.73.33 1.07.56l.618.419.68-.216c.377-.115.88-.305 1.107-.433.214-.114.403-.178.403-.14 0 .217-.465.954-.856 1.36-.529.573-.377.624.693.242.642-.216.654-.216.529.026-.076.127-.466.572-.882.978-.705.7-.743.776-.743 1.36 0 .903-.428 2.784-.856 3.814-.793 1.932-2.493 3.928-4.193 4.932-2.392 1.41-5.577 1.766-8.259.94-.894-.28-2.43-.991-2.43-1.118 0-.038.466-.09 1.032-.102a7.088 7.088 0 003.375-.94l.68-.407-.781-.267c-1.108-.382-2.103-1.259-2.354-2.085-.076-.267-.05-.28.654-.28l.73-.012-.616-.293c-.73-.368-1.398-.991-1.725-1.627-.24-.457-.542-1.614-.454-1.703.026-.038.29.038.592.14.869.318.982.241.479-.292-.945-.966-1.234-2.403-.781-3.763l.214-.61.831.826c1.7 1.665 3.702 2.657 5.993 2.95l.63.075z"
      clipRule="evenodd"
    />
  </svg>
);

const Linkedin: FC<SocialProps> = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0zM11.522 19.878h5.438V36.22h-5.438V19.878zm5.797-5.055C17.284 13.22 16.138 12 14.277 12c-1.86 0-3.077 1.22-3.077 2.823 0 1.569 1.18 2.824 3.006 2.824h.035c1.897 0 3.078-1.255 3.078-2.824zm12.994 4.671c3.58 0 6.262 2.336 6.262 7.356v9.37h-5.439v-8.743c0-2.196-.787-3.695-2.755-3.695-1.503 0-2.398 1.01-2.791 1.986-.144.35-.18.837-.18 1.325v9.127h-5.439s.072-14.808 0-16.342h5.44v2.315c.721-1.113 2.014-2.699 4.902-2.699z"
      clipRule="evenodd"
    />
  </svg>
);

const WhatsApp: FC<SocialProps> = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M0 24c0 13.255 10.745 24 24 24s24-10.745 24-24S37.255 0 24 0 0 10.745 0 24zm39.062-.919c-.003 7.867-6.402 14.268-14.27 14.271h-.007c-2.388 0-4.735-.6-6.82-1.736L10.4 37.6l2.025-7.395a14.246 14.246 0 01-1.905-7.135c.003-7.868 6.405-14.27 14.27-14.27a14.18 14.18 0 0110.096 4.185 14.184 14.184 0 014.176 10.096z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M13.826 34.21l4.49-1.178.433.257a11.845 11.845 0 006.037 1.653h.005c6.538 0 11.859-5.32 11.862-11.861a11.79 11.79 0 00-3.471-8.392 11.78 11.78 0 00-8.386-3.479c-6.543 0-11.864 5.321-11.867 11.861 0 2.241.626 4.424 1.814 6.313l.282.448-1.199 4.378zm17.666-8.056c.25.12.417.201.489.321.089.149.089.863-.208 1.696s-1.722 1.593-2.407 1.695c-.614.092-1.392.13-2.246-.141a20.476 20.476 0 01-2.033-.751c-3.343-1.444-5.602-4.684-6.029-5.296a4.23 4.23 0 00-.062-.088l-.003-.004c-.189-.252-1.453-1.94-1.453-3.685 0-1.643.806-2.504 1.178-2.9l.07-.075a1.31 1.31 0 01.95-.446c.238 0 .476.002.684.012.026.002.052.002.08.001.208 0 .467-.002.722.612.099.236.242.586.394.955.307.747.646 1.573.706 1.692.089.179.148.387.03.625l-.05.102c-.09.182-.156.316-.307.493-.06.07-.121.144-.183.22-.123.149-.245.298-.352.404-.179.178-.364.371-.157.728.209.357.924 1.525 1.984 2.47 1.14 1.017 2.13 1.447 2.632 1.664.098.043.178.077.236.106.356.179.564.15.772-.089.208-.238.892-1.041 1.13-1.398.237-.357.475-.298.802-.179.327.12 2.08.982 2.436 1.16l.195.096z"
      clipRule="evenodd"
    />
  </svg>
);

export enum SocialTypes {
  'facebook' = 'facebook',
  'twitter' = 'twitter',
  'instagram' = 'instagram',
  'linkedin' = 'linkedin',
  'whatsapp' = 'whatsapp',
}

interface Props {
  type: SocialTypes;
  size?: number;
  color?: string;
}

const SocialIcon: FC<Props> = ({
  type,
  size = 36,
  color = COLORS.black,
}) => ({
  facebook: <Facebook size={size} color={color} />,
  twitter: <Twitter size={size} color={color} />,
  instagram: <Instagram size={size} color={color} />,
  linkedin: <Linkedin size={size} color={color} />,
  whatsapp: <WhatsApp size={size} color={color} />,
}[type]);

export default SocialIcon;
