import HideCrisp from '@components/atoms/HideCrisp';
import WhatsAppIcon from '@components/atoms/icons/WhatsAppIcon';
import { trackEvent } from '@lib/services/analytics';
import React from 'react';
import styled from 'styled-components';

export function whatsAppMsgLink({
  text,
  lunaNumber,
}: {
  text: string;
  lunaNumber: 'chatwoot' | 'sales';
}) {
  const map = {
    chatwoot: '551150399198',
    sales: '551149337868',
  };

  return `https://api.whatsapp.com/send?1=pt_BR&phone=${map[lunaNumber]}&text=${text}`;
}

const Wrapper = styled.a`
  z-index: 9999999;
  display: block;
  cursor: pointer;
  position: fixed;
  right: 24px;

  width: 60px;
  height: 60px;
  display: block;
  border-radius: 60px;
  transition: transform 0.15s ease-in-out;

  background-color: transparent;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const WhatsAppFixedButton = ({
  text,
  lunaNumber = 'chatwoot',
  bottom = 20,
}: {
  text: string;
  lunaNumber?: 'chatwoot' | 'sales';
  bottom?: number;
}) => (
  <>
    <HideCrisp />
    <Wrapper
      href={whatsAppMsgLink({ text, lunaNumber })}
      onClick={() => trackEvent('clicked whatsapp fixed button')}
      target="_blank"
      style={{ bottom }}
    >
      <WhatsAppIcon />
    </Wrapper>
  </>
);
