import COLORS from 'lib/constants/colors';
import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  style?: CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  className?: string;
}

const StyledButton = styled.button`
  font-size: 16px;

  border: none;
  padding: 0px;
  color: ${COLORS.aqua};
  background: transparent;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GhostButton: FC<Props> = ({
  type = 'button',
  children,
  ...props
}) => (
  <StyledButton
    type={type}
    {...props}
  >
    {children}
  </StyledButton>
);

export default GhostButton;
